import { CanActivate, Route, Routes } from '@angular/router';
import {
  ACCOUNT_OVERVIEW,
  APITokenGuard,
  API_AUTH_STATUS,
  APP_SESSION_DATA,
  AccountOverviewResolver,
  AgentInContextGuard,
  AgentReportsSummaryGuard,
  ApiAuthResolver,
  ApplicationSessionResolver,
  AuthGuard,
  BrowserSupportGuard,
  CanDeactivateGuard,
  ConsumerAccountSummaryGuard,
  ENROLLMENT_TRANSACTION_ID,
  EnrollmentGuard,
  EnrollmentTransactionResolver,
  GOOGLE_TAG_MANAGER,
  GenericFormTransactionResolver,
  GoogleTagManagerResolver,
  HRAFormTransactionResolver,
  LIVE_EDIT_ROOT,
  LOGIN_STATUS,
  LoginStatusResolver,
  MedicareGroupGuard,
  Page,
  PageTags,
  RouteGeneratorOptions,
  SET_SITE_TYPE,
  SiteTypeResolver,
  StandaloneFormTransactionResolver,
  WidgetValidationGuard
} from 'vnext-shared';
import { environment } from '../../../environments/environment';
import { LiveEditRootComponent } from '../live-edit/live-edit-root/live-edit-root.component';
import { TemplateLookupService } from '../services/template.lookup.service';
import { caseInsensitiveMatcher } from './case-insensitive.matcher';

export class RouteBuilder {
  /**
   * Returns an array of activation guards given a page.
   */
  private static getActivationGuards(page: Page): CanActivate[] {
    const guards = [];
    if (!environment.liveedit) {
      guards.push(BrowserSupportGuard);
      // All routes should have AuthGuard activation present.
      guards.push(AuthGuard);
    }
    const pageTags = page.tags;
    if (pageTags?.length) {
      if (pageTags.includes(PageTags.CONSUMER_ACCOUNT_SUMMARY)) {
        guards.push(ConsumerAccountSummaryGuard);
      }
      if (pageTags.includes(PageTags.REPORTS_SUMMARY)) {
        guards.push(AgentReportsSummaryGuard);
      }
      if (pageTags.includes(PageTags.HOME) || pageTags.includes(PageTags.PLAN_LIST)) {
        guards.push(MedicareGroupGuard);
      }
      if (!pageTags.includes(PageTags.PAGE_NOT_FOUND)) {
        guards.push(AgentInContextGuard);
      }
    }

    return guards;
  }

  // TODO: use the param or environment var? update across the board accordingly
  static build(config: any, options?: RouteGeneratorOptions): Routes {
    let routes = [];
    if (config.pages) {
      routes = config.pages
        .filter(page => !page.disabled)
        .filter(page => page?.tags?.find(tag => !options.disabledRoutes.includes(tag)))
        .reduce((pages, page) => {
          if (page.pageName) {
            if (page?.tags && this.includesFormPageTag(page.tags) && config?.homepage !== page.pageName) {
              const pageTemplate = TemplateLookupService.getTemplate(page.template);
              if (pageTemplate) {
                pages.push(
                  {
                    matcher: caseInsensitiveMatcher(page.pageName),
                    component: pageTemplate,
                    pathMatch: 'full',
                    data: { title: page.title },
                    canDeactivate: [APITokenGuard],
                    canActivate: [AuthGuard, EnrollmentGuard, AgentInContextGuard],
                    resolve: {
                      [APP_SESSION_DATA]: ApplicationSessionResolver,
                      [ENROLLMENT_TRANSACTION_ID]: this.getFormResolver(page.tags),
                      [LOGIN_STATUS]: LoginStatusResolver
                    }
                  },
                  {
                    matcher: caseInsensitiveMatcher(`${page.pageName}/:pageName`),
                    component: pageTemplate,
                    pathMatch: 'full',
                    // TODO: eventually account for uri param in page title
                    canDeactivate: [APITokenGuard],
                    canActivate: [AuthGuard, EnrollmentGuard, AgentInContextGuard],
                    data: {
                      title: page.title,
                      reuseComponent: page.tags.includes(PageTags.ENROLLMENT) || page.tags.includes(PageTags.STANDALONE_FORM)
                    },
                    resolve: {
                      [APP_SESSION_DATA]: ApplicationSessionResolver,
                      [ENROLLMENT_TRANSACTION_ID]: this.getFormResolver(page.tags),
                      [LOGIN_STATUS]: LoginStatusResolver
                    }
                  }
                );
              }
            } else {
              const pageTemplate = TemplateLookupService.getTemplate(page.template);
              if (pageTemplate) {
                pages.push({
                  matcher: caseInsensitiveMatcher(page.pageName),
                  component: pageTemplate,
                  pathMatch: 'full',
                  data: { title: page.title },
                  canDeactivate: [WidgetValidationGuard, CanDeactivateGuard, APITokenGuard],
                  canActivate: this.getActivationGuards(page),
                  resolve: {
                    [GOOGLE_TAG_MANAGER]: GoogleTagManagerResolver,
                    [SET_SITE_TYPE]: SiteTypeResolver,
                    [APP_SESSION_DATA]: ApplicationSessionResolver,
                    [LOGIN_STATUS]: LoginStatusResolver,
                    [ACCOUNT_OVERVIEW]: AccountOverviewResolver
                  }
                });
              } else if (page.redirectTo) {
                pages.push({
                  matcher: caseInsensitiveMatcher(page.pageName),
                  redirectTo: page.redirectTo,
                  pathMatch: 'full',
                  resolve: {
                    [SET_SITE_TYPE]: SiteTypeResolver,
                    [APP_SESSION_DATA]: ApplicationSessionResolver,
                    [LOGIN_STATUS]: LoginStatusResolver
                  }
                });
              }
            }
          }
          return pages;
        }, []);
    }

    // redirect to homepage when we hit root path
    if (config.homepage) {
      routes.unshift({
        path: '',
        redirectTo: config.homepage,
        pathMatch: 'full',
        resolve: {
          [APP_SESSION_DATA]: ApplicationSessionResolver,
          [LOGIN_STATUS]: LoginStatusResolver
        }
      });
    }

    if (environment.liveedit) {
      const liveEditRoot = RouteBuilder.buildLiveEditRoot();
      liveEditRoot.children.unshift(...routes);
      return [liveEditRoot];
    }
    return routes;
  }

  static getFormResolver(pageTags): any {
    if (pageTags.includes(PageTags.HRA_FORM)) {
      return HRAFormTransactionResolver;
    } else if (pageTags.includes(PageTags.STANDALONE_FORM)) {
      return StandaloneFormTransactionResolver;
    } else if (pageTags.includes(PageTags.GENERIC_FORM) || pageTags.includes(PageTags.ANCILLARY_FORM)) {
      return GenericFormTransactionResolver;
    } else {
      return EnrollmentTransactionResolver;
    }
  }

  static includesFormPageTag(pageTags): boolean {
    return (
      pageTags.includes(PageTags.ENROLLMENT) ||
      pageTags.includes(PageTags.GENERIC_FORM) ||
      pageTags.includes(PageTags.STANDALONE_FORM) ||
      pageTags.includes(PageTags.HRA_FORM) ||
      pageTags.includes(PageTags.ANCILLARY_FORM)
    );
  }

  static buildLiveEditRoot(): Route {
    return {
      matcher: caseInsensitiveMatcher(LIVE_EDIT_ROOT),
      component: LiveEditRootComponent,
      children: [{ path: '**', component: LiveEditRootComponent }],
      resolve: {
        [API_AUTH_STATUS]: ApiAuthResolver
      }
    };
  }
}
